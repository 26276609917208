export const SET_DATA = '@CLIENTS/SET_DATA';
export const ADD = '@CLIENTS/ADD';
export const READ = '@CLIENTS/READ';
export const READ_FAIL = '@CLIENTS/READ_FAIL';
export const READ_SUCCESS = '@CLIENTS/READ_SUCCESS';
export const CREATE = '@CLIENTS/CREATE';
export const CREATE_FAIL = '@CLIENTS/CREATE_FAIL';
export const CREATE_SUCCESS = '@CLIENTS/CREATE_SUCCESS';
export const UPDATE = '@CLIENTS/UPDATE';
export const UPDATE_FAIL = '@CLIENTS/UPDATE_FAIL';
export const UPDATE_SUCCESS = '@CLIENTS/UPDATE_SUCCESS';
export const DELETE = '@CLIENTS/DELETE';
export const DELETE_FAIL = '@CLIENTS/DELETE_FAIL';
export const DELETE_SUCCESS = '@CLIENTS/DELETE_SUCCESS';
export const REQUEST_READ = '@CLIENTS/REQUEST_READ';
export const REQUEST_CREATE = '@CLIENTS/REQUEST_CREATE';
export const REQUEST_UPDATE = '@CLIENTS/REQUEST_UPDATE';
export const REQUEST_DELETE = '@CLIENTS/REQUEST_DELETE';
export const REQUEST_UPDATE_REMOTE_CONNECTIONS = '@CLIENTS/REQUEST_UPDATE_REMOTE_CONNECTIONS';
export const REQUEST_UPDATE_REMOTE_CONNECTIONS_SUCCESS = '@CLIENTS/REQUEST_UPDATE_REMOTE_CONNECTIONS_SUCCESS';
export const UPDATE_REMOTE_CONNECTIONS = '@CLIENTS/UPDATE_REMOTE_CONNECTIONS';
export const REQUEST_UPDATE_CLIENT_API_KEY = '@CLIENTS/REQUEST_UPDATE_API_KEY';