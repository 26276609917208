import { takeLatest, call, put, all, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import i18next from "i18next";
import * as types from "./types";
import { setDevicesData } from "./actions";
import api from "app/services/api";
import ToastService from "../../../services/ToastService";

export function* create({ payload }) {
  const { formData, callback } = payload;
  try {
    yield put({
      type: types.CREATE,
    });
    yield call(api.post, "/devices", formData);
    yield put({
      type: types.CREATE_SUCCESS,
    });
    ToastService.showSuccess("devicesList.successCreate");
    callback();
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.CREATE_FAIL,
    });
  }
}

export function* read({ payload }) {
  try {
    yield put({
      type: types.READ,
    });
    const { data } = yield call(api.get, "/devices", {
      params: payload,
    });

    yield put(setDevicesData(data));
    yield put({
      type: types.READ_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.READ_FAIL,
    });
  }
}

export function* update({ payload }) {
  const { id, formData, callback } = payload;
  try {
    yield put({
      type: types.UPDATE,
    });
    yield call(api.put, `/devices/${id}`, formData);
    yield put({
      type: types.UPDATE_SUCCESS,
    });
    ToastService.showSuccess("devicesList.successUpdate");
    callback();
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.UPDATE_FAIL,
    });
  }
}

export function* inactivate({ payload }) {
  const { id, callback } = payload;
  try {
    yield put({
      type: types.DELETE,
    });
    yield call(api.delete, `/devices/${id}`);
    yield put({
      type: types.DELETE_SUCCESS,
    });
    ToastService.showSuccess("devicesList.successInactivate");
    callback();
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.DELETE_FAIL,
    });
    callback();
  }
}

export function* createDevicesTask({ payload }) {
  const { formData, callback } = payload;
  try {
    yield put({
      type: types.CREATE_DEVICES_TASK,
    });
    yield call(api.post, "/devices/tasks", formData);
    yield put({
      type: types.CREATE_DEVICES_TASK_SUCCESS,
    });
    ToastService.showSuccess("devicesTask.successCreate");
    callback();
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.CREATE_DEVICES_TASK_FAIL,
    });
  }
}

export function* readFavoriteDevices({ payload }) {
  try {
    yield put({
      type: types.READ_FAVORITE_DEVICES,
    });
    const { data } = yield call(api.get, `/devices/favorites`, {
      params: payload,
    });
    yield put({
      type: types.READ_FAVORITE_DEVICES_SUCCESS,
    });
    yield put({
      type: types.SET_FAVORITE_DEVICES,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.READ_FAVORITE_DEVICES_FAIL,
    });
  }
}

export function* readDevicesWithAlerts({ payload }) {
  try {
    yield put({
      type: types.READ_DEVICES_WITH_ALERTS,
    });
    const { data } = yield call(api.get, `/devices/with-alerts`, {
      params: payload,
    });
    yield put({
      type: types.READ_DEVICES_WITH_ALERTS_SUCCESS,
    });
    yield put({
      type: types.SET_DEVICES_WITH_ALERTS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.READ_DEVICES_WITH_ALERTS_FAIL,
    });
  }
}

export function* createFavoriteDevice({ payload }) {
  const id = payload;
  try {
    yield call(api.post, `/devices/favorite/${id}`);
    ToastService.showSuccess("deviceFavorite.successCreate");
  } catch (error) {
    ToastService.showApiRequestError(error);
  }
}

export function* deleteFavoriteDevice({ payload }) {
  const { id, callback } = payload;
  try {
    yield call(api.delete, `/devices/favorite/${id}`);
    if (callback) {
      callback();
    }
    ToastService.showSuccess("deviceFavorite.successDeleted");

  } catch (error) {
    ToastService.showApiRequestError(error);
  }
}

export function* updateDeviceStatus({ payload }) {
  const { serial_number } = payload;
  try {
    ToastService.showSuccess("devicesTask.refresh_device.successCreate");
    yield call(api.put, `/devices/status/${serial_number}`);
  } catch (error) {
    ToastService.showApiRequestError(error);
  }
}

export function* activeteDeviceLicense({ payload }) {
  const { serial_number, license_type } = payload;
  try {
    const { data } = yield call(
      api.get,
      `/devices/activetelicense?serial_number=${serial_number}&license_type=${license_type}`
    );

    if (!data) toast.error(i18next.t("apiResponse.unknown"));

    if (data.status === 200 && data.msg === "OK") {
      toast.success(data.message);
    } else {
      toast.error(i18next.t("apiResponse.devices.licence_denied"));
    }
  } catch (error) {
    ToastService.showApiRequestError(error);
  }
}

export function* blockIFAgent({ payload }) {
  const macros = { serial_number: payload.serial_number.replace("PLC", "") };
  const toastId = ToastService.showInfo(
    "devicesList.blockingIFAgentRequest",
    macros,
    { autoClose: false }
  );
  try {
    yield call(
      api.post,
      `/devices/if-agent/block-api?serial_number=${payload.serial_number}`
    );
    ToastService.dismissToast(toastId);
    ToastService.showSuccess("devicesList.ifAgentBlockedSuccessfully", macros);
  } catch (error) {
    ToastService.dismissToast(toastId);
    ToastService.showApiRequestError(error, macros);
  }
}

export function* unblockIFAgent({ payload }) {
  const macros = { serial_number: payload.serial_number.replace("PLC", "") };
  const toastId = ToastService.showInfo(
    "devicesList.unblockingIFAgentRequest",
    macros,
    { autoClose: false }
  );
  try {
    yield call(
      api.post,
      `/devices/if-agent/unblock-api?serial_number=${payload.serial_number}`
    );
    ToastService.dismissToast(toastId);
    ToastService.showSuccess(
      "devicesList.ifAgentUnblockedSuccessfully",
      macros
    );
  } catch (error) {
    ToastService.dismissToast(toastId);
    ToastService.showApiRequestError(error, macros);
  }
}

export function* readDevicesStatistics({ payload }) {
  try {
    yield put({
      type: types.READ_DEVICES_STATISTICS,
    });
    const { data } = yield call(api.get, `/devices/statistics`, {
      params: payload,
    });

    yield put({
      type: types.READ_DEVICES_STATISTICS_SUCCESS,
    });
    yield put({
      type: types.SET_DEVICES_STATISTICS,
      payload: data,
    });
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.READ_DEVICES_STATISTICS_FAIL,
    });
  }
}

export function* readDevicesAlerts({ payload }) {
  try {
    yield put({
      type: types.READ_DEVICES_ALERTS,
    });
    const { data } = yield call(api.get, `/devices/alerts`, {
      params: payload,
    });

    yield put({
      type: types.READ_DEVICES_ALERTS_SUCCESS,
    });
    yield put({
      type: types.SET_DEVICES_ALERTS,
      payload: data,
    });
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.READ_DEVICES_ALERTS_FAIL,
    });
  }
}

export function* blockSystem({ payload }) {
  const macros = { serial_number: payload.serial_number.replace("PLC", "") };
  const toastId = ToastService.showInfo(
    "devicesList.blockingSystemRequest",
    macros,
    { autoClose: false }
  );
  try {
    yield call(
      api.post,
      `/devices/if-agent/block-system?serial_number=${payload.serial_number}`
    );
    ToastService.dismissToast(toastId);
    ToastService.showSuccess("devicesList.systemBlockedSuccessfully", macros);
  } catch (error) {
    ToastService.dismissToast(toastId);
    ToastService.showApiRequestError(error, macros);
  }
}

export function* unblockSystem({ payload }) {
  const macros = { serial_number: payload.serial_number.replace("PLC", "") };
  const toastId = ToastService.showInfo(
    "devicesList.unblockingSystemRequest",
    macros,
    { autoClose: false }
  );
  try {
    yield call(
      api.post,
      `/devices/if-agent/unblock-system?serial_number=${payload.serial_number}`
    );
    ToastService.dismissToast(toastId);
    ToastService.showSuccess(
      "devicesList.systemUnblockedSuccessfully",
      macros
    );
  } catch (error) {
    ToastService.dismissToast(toastId);
    ToastService.showApiRequestError(error, macros);
  }
}

export function* readDevicesProducts({ payload }) {
  try {
    yield put({
      type: types.READ_DEVICES_PRODUCTS,
    });
    const { data } = yield call(api.get, `/devices/products/available`, {
      params: payload,
    });

    yield put({
      type: types.READ_DEVICES_PRODUCTS_SUCCESS,
    });
    yield put({
      type: types.SET_DEVICES_PRODUCTS,
      payload: data,
    });
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.READ_DEVICES_PRODUCTS_FAIL,
    });
  }
}

export function* updateDeviceProduct({ payload }) {
  const { formData, callback } = payload;
  console.log({ formData })

  try {
    yield put({
      type: types.UPDATE_DEVICE_PRODUCT,
    });
    
    yield call(api.post, "/devices/product/update", formData);
   
    yield put({
      type: types.UPDATE_DEVICE_PRODUCT_SUCCESS,
    });
   
    ToastService.showSuccess("devicesTask.update_product.successCreate");
    callback();
  } catch (err) {
    ToastService.showApiRequestError(err);
    yield put({
      type: types.UPDATE_DEVICE_PRODUCT_FAIL,
    });
  }
}

export function* readDeviceTasks({ payload }) {
  const serial_number = payload;
  try {
    yield put({
      type: types.READ_DEVICE_TASKS,
    });
    const { data } = yield call(api.get, `/devices/tasks/${serial_number}`);
    yield put({
      type: types.READ_DEVICE_TASKS_SUCCESS,
    });
    yield put({
      type: types.SET_DEVICE_TASKS,
      payload: data,
    });
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.READ_DEVICE_TASKS_FAIL,
    });
  }
}

export function* reactivate({ payload }) {
  const { id, callback } = payload;
  try {
    yield put({
      type: types.REACTIVATE,
    });
    yield call(api.put, `/devices/reactivate/${id}`);
    yield put({
      type: types.REACTIVATE_SUCCESS,
    });
    ToastService.showSuccess("devicesList.successReactivate");
    callback();
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.REACTIVATE_FAIL,
    });
    callback();
  }
}

export default all([
  takeLatest(types.REQUEST_CREATE, create),
  takeLatest(types.REQUEST_DEVICES_TASK_CREATE, createDevicesTask),
  takeLatest(types.REQUEST_READ, read),
  takeLatest(types.REQUEST_FAVORITE_DEVICES, readFavoriteDevices),
  takeLatest(types.REQUEST_DEVICES_WITH_ALERTS, readDevicesWithAlerts),
  takeEvery(types.REQUEST_DELETE_FAVORITE_DEVICE, deleteFavoriteDevice),
  takeEvery(types.REQUEST_CREATE_FAVORITE_DEVICE, createFavoriteDevice),
  takeLatest(types.REQUEST_UPDATE, update),
  takeLatest(types.REQUEST_UPDATE_STATUS, updateDeviceStatus),
  takeLatest(types.REQUEST_DELETE, inactivate),
  takeLatest(types.REQUEST_ACTIVE_DEVICE_LICENSE, activeteDeviceLicense),
  takeLatest(types.REQUEST_BLOCK_IF_AGENT, blockIFAgent),
  takeLatest(types.REQUEST_UNBLOCK_IF_AGENT, unblockIFAgent),
  takeLatest(types.REQUEST_DEVICES_STATISTICS, readDevicesStatistics),
  takeLatest(types.REQUEST_DEVICES_ALERTS, readDevicesAlerts),
  takeLatest(types.REQUEST_BLOCK_SYSTEM, blockSystem),
  takeLatest(types.REQUEST_UNBLOCK_SYSTEM, unblockSystem),
  takeLatest(types.REQUEST_DEVICES_PRODUCTS, readDevicesProducts),
  takeLatest(types.REQUEST_UPDATE_DEVICE_PRODUCT, updateDeviceProduct),
  takeLatest(types.REQUEST_DEVICE_TASKS, readDeviceTasks),
  takeLatest(types.REQUEST_REACTIVATE, reactivate),
]);
