import { EgretLoadable } from "egret";
import { authRoles } from "~/app/auth/authRoles";

const UsersConnections = EgretLoadable({
  loader: () => import("./index"),
});

const UsersConnectionsRoute = [
  {
    path: "/users-connections/",
    exact: true,
    component: UsersConnections,
    auth: authRoles.all,
  },
];

export default UsersConnectionsRoute;
