import produce from "immer";
import * as types from "./types";

const INITIAL_STATE = {
  read_loading: false,
  read_success: false,
  data: [],
};

const importsTracerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_DATA:
      return produce(state, (draft) => {
        draft.data = [ ...action.payload ];
      });
    case types.READ:
      return produce(state, (draft) => {
        draft.read_loading = true;
      });
    case types.READ_SUCCESS:
      return produce(state, (draft) => {
        draft.read_loading = false;
        draft.read_success = true;
      });
    case types.READ_FAIL:
      return produce(state, (draft) => {
        draft.read_loading = false;
        draft.read_success = false;
      });
    default:
      return state;
  }
};

export default importsTracerReducer;
