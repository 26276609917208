import { EgretLoadable } from 'egret';
import { authRoles } from '~/app/auth/authRoles';

const UserProfile = EgretLoadable({
  loader: () => import('./index')
});

const UserProfileRoute = [
  {
    path: '/user-profile',
    exact: true,
    component: UserProfile,
    auth: authRoles.all,
  }
];

export default UserProfileRoute;
