export const SET_DATA = '@USERS/SET_DATA';
export const ADD = '@USERS/ADD';
export const READ = '@USERS/READ';
export const READ_FAIL = '@USERS/READ_FAIL';
export const READ_SUCCESS = '@USERS/READ_SUCCESS';
export const CREATE = '@USERS/CREATE';
export const CREATE_FAIL = '@USERS/CREATE_FAIL';
export const CREATE_SUCCESS = '@USERS/CREATE_SUCCESS';
export const UPDATE = '@USERS/UPDATE';
export const UPDATE_FAIL = '@USERS/UPDATE_FAIL';
export const UPDATE_SUCCESS = '@USERS/UPDATE_SUCCESS';
export const DELETE = '@USERS/DELETE';
export const DELETE_FAIL = '@USERS/DELETE_FAIL';
export const DELETE_SUCCESS = '@USERS/DELETE_SUCCESS';
export const REQUEST_READ = '@USERS/REQUEST_READ';
export const REQUEST_CREATE = '@USERS/REQUEST_CREATE';
export const REQUEST_UPDATE = '@USERS/REQUEST_UPDATE';
export const REQUEST_DELETE = '@USERS/REQUEST_DELETE';