import { takeLatest, call, put, all } from "redux-saga/effects";
import * as types from "./types";
import { setImportsData } from "./actions";
import api from "app/services/api";
import objectToQueryString from "app/utils/objectToQueryString";
import ToastService from "../../../services/ToastService";

export function* read({ payload }) {
  try {
    yield put({
      type: types.READ,
    });
    const { data } = yield call(
      api.get,
      `/imports${objectToQueryString(payload)}`
    );
    yield put(setImportsData(data));
    yield put({
      type: types.READ_SUCCESS,
    });
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.READ_FAIL,
    });
  }
}

export function* create({ payload }) {
  const { importData } = payload;
  try {
    const { data } = yield call(api.post, "/imports", importData);

    ToastService.showSuccess("devicesList.import.success", {
      import_id: data.id,
    });
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.CREATE_FAIL,
    });
  }
}

export default all([
  takeLatest(types.REQUEST_READ, read),
  takeLatest(types.REQUEST_CREATE, create),
]);
