import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  create_loading: false,
  create_success: false,
  delete_loading: false,
  delete_success: false,
  data: [],
};

const remoteConnectionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CREATE:
      return produce(state, draft => {
        draft.create_loading = true;
      });
    case types.CREATE_SUCCESS:
      return produce(state, draft => {
        draft.create_loading = false;
        draft.create_success = true;
      });
    case types.CREATE_FAIL:
      return produce(state, draft => {
        draft.create_loading = false;
        draft.create_success = false;
      });
    case types.DELETE:
      return produce(state, draft => {
        draft.delete_loading = true;
      });
    case types.DELETE_SUCCESS:
      return produce(state, draft => {
        draft.delete_loading = false;
        draft.delete_success = true;
      });
    case types.DELETE_FAIL:
      return produce(state, draft => {
        draft.delete_loading = false;
        draft.delete_success = false;
      });
    case types.ADD_REMOTE_CONNECTION:
      return produce(state, draft => {
        draft.data = [...state.data, action.payload];
      });
    case types.REMOVE_REMOTE_CONNECTION:
      return produce(state, draft => {
        draft.data = state.data.filter(
          item => item.connection_id !== action.payload
        );
      });
    default:
      return state;
  }
};

export default remoteConnectionsReducer;
