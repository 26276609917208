export const SET_DATA = '@LOCALES/SET_DATA';
export const ADD = '@LOCALES/ADD';
export const READ = '@LOCALES/READ';
export const READ_FAIL = '@LOCALES/READ_FAIL';
export const READ_SUCCESS = '@LOCALES/READ_SUCCESS';
export const CREATE = '@LOCALES/CREATE';
export const CREATE_FAIL = '@LOCALES/CREATE_FAIL';
export const CREATE_SUCCESS = '@LOCALES/CREATE_SUCCESS';
export const UPDATE = '@LOCALES/UPDATE';
export const UPDATE_FAIL = '@LOCALES/UPDATE_FAIL';
export const UPDATE_SUCCESS = '@LOCALES/UPDATE_SUCCESS';
export const DELETE = '@LOCALES/DELETE';
export const DELETE_FAIL = '@LOCALES/DELETE_FAIL';
export const DELETE_SUCCESS = '@LOCALES/DELETE_SUCCESS';
export const REQUEST_READ = '@LOCALES/REQUEST_READ';
export const REQUEST_CREATE = '@LOCALES/REQUEST_CREATE';
export const REQUEST_UPDATE = '@LOCALES/REQUEST_UPDATE';
export const REQUEST_DELETE = '@LOCALES/REQUEST_DELETE';
export const REQUEST_STATUS_UPDATE = '@LOCALES/REQUEST_STATUS_UPDATE';
export const STATUS_UPDATE = '@LOCALES/STATUS_UPDATE';