import { toast } from "react-toastify";
import i18next from "i18next";
import md5 from "md5";

class ToastService {
  messagesTokensId = {
    errors: {},
  };

  _getTranslatedMessage(message, macros) {
    return i18next.exists(message) ? i18next.t(message, macros) : message;
  }
  showSuccess(message, macros, options) {
    return toast.success(this._getTranslatedMessage(message, macros), options);
  }
  showError(message, macros, options) {
    const msg = this._getTranslatedMessage(message, macros);
    const hashMsg = md5(msg);
    if (hashMsg in this.messagesTokensId.errors) {
      const toastId = this.messagesTokensId.errors[hashMsg];
      if (toast.isActive(toastId)) {
        return toastId;
      }
      delete this.messagesTokensId.errors[hashMsg];
    }
    const toastId = toast.error(msg, options);
    this.messagesTokensId.errors[hashMsg] = toastId;
    return toastId;
  }
  showInfo(message, macros, options) {
    return toast.info(this._getTranslatedMessage(message, macros), options);
  }
  showApiRequestError(error, macros, options) {
    // console.log(error);
    return error.response
      ? this.showError(
          `apiResponse.${error.response.data.errorToken}`,
          macros,
          options
        )
      : this.showError("apiResponse.unknown", macros, options);
  }

  dismissToast(toastId) {
    if (toast.isActive(toastId)) {
      toast.dismiss(toastId);
    }
  }
}

export default new ToastService();
