import { EgretLoadable } from "egret";
import { authRoles } from "~/app/auth/authRoles";

const Imports = EgretLoadable({
  loader: () => import("./index"),
});

const ImportsRoute = [
  {
    path: "/imports/:id+",
    exact: true,
    component: Imports,
    auth: authRoles.all,
  },
];

export default ImportsRoute;
