import produce from "immer";
import * as types from "./types";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: {
    username: null,
    password: null,
  },
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN:
      return produce(state, (draft) => {
        draft = {
          ...state,
          success: false,
          loading: true,
        };
      });
    case types.SUCCESS:
      return produce(state, (draft) => {
        draft = {
          ...state,
          success: true,
          loading: false,
        };
      });
    case types.ERROR:
      return produce(state, (draft) => {
        draft = {
          success: false,
          loading: false,
          error: { ...action.payload },
        };
      });
    case types.RESET_PASSWORD:
      return produce(state, (draft) => {
        draft = {
          ...state,
          success: true,
          loading: false,
        };
      });

    case types.LOGOUT:
      return produce(state, (draft) => {
        draft = {
          ...state,
          success: false,
          loading: true,
        };
      });

    default:
      return state;
  }
};

export default loginReducer;
