import { combineReducers } from "redux";
import * as sessionTypes from "./session/types";

import notification from "./notification/reducer";
import subclients from "./subclients/reducer";
import dashboard from "./dashboard/reducer";
import clients from "./clients/reducer";
import layout from "./layout/reducer";
import locales from "./locales/reducer";
import login from "./login/reducer";
import session from "./session/reducer";
import users from "./users/reducer";

import devices from "./devices/reducer";
import deviceTypes from "./deviceTypes/reducer";
import deviceModels from "./deviceModels/reducer";
import deviceImport from "./deviceImport/reducer";
import remoteConnections from "./remoteConnections/reducer";
import logs from "./logs/reducer";
import apikeys from "./apikeys/reducer";
import importsTracer from "./importsTracer/reducer";
import usersConnections from './usersConnections/reducer';

const appReducer = combineReducers({
  notification,
  subclients,
  dashboard,
  clients,
  locales,
  layout,
  login,
  session,
  users,
  devices,
  deviceTypes,
  deviceModels,
  deviceImport,
  remoteConnections,
  apikeys,
  logs,
  importsTracer,
  usersConnections,
});

const rootReducer = (state, action) => {
  if (action.type === sessionTypes.USER_LOGGED_OUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
