export const SET_DATA = '@DEVICE_MODELS/SET_DATA';
export const ADD = '@DEVICE_MODELS/ADD';
export const READ = '@DEVICE_MODELS/READ';
export const READ_FAIL = '@DEVICE_MODELS/READ_FAIL';
export const READ_SUCCESS = '@DEVICE_MODELS/READ_SUCCESS';
export const CREATE = '@DEVICE_MODELS/CREATE';
export const CREATE_FAIL = '@DEVICE_MODELS/CREATE_FAIL';
export const CREATE_SUCCESS = '@DEVICE_MODELS/CREATE_SUCCESS';
export const UPDATE = '@DEVICE_MODELS/UPDATE';
export const UPDATE_FAIL = '@DEVICE_MODELS/UPDATE_FAIL';
export const UPDATE_SUCCESS = '@DEVICE_MODELS/UPDATE_SUCCESS';
export const DELETE = '@DEVICE_MODELS/DELETE';
export const DELETE_FAIL = '@DEVICE_MODELS/DELETE_FAIL';
export const DELETE_SUCCESS = '@DEVICE_MODELS/DELETE_SUCCESS';
export const REQUEST_READ = '@DEVICE_MODELS/REQUEST_READ';
export const REQUEST_CREATE = '@DEVICE_MODELS/REQUEST_CREATE';
export const REQUEST_UPDATE = '@DEVICE_MODELS/REQUEST_UPDATE';
export const REQUEST_DELETE = '@DEVICE_MODELS/REQUEST_DELETE';