import "../styles/_app.scss";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import EgretTheme from "./EgretLayout/EgretTheme/EgretTheme";
import AppContext from "./appContext";
import history from "history.js";

import "../config/ReactotronConfig";
import routes from "./RootRoutes";
import store from "app/store";
import EgretLayout from "./EgretLayout/EgretLayout";
import AuthGuard from "./auth/AuthGuard";
import setupInterceptors from "./services/setupInterceptors";

const App = () => {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={store}>
        <EgretTheme>
          <Router history={history}>
            <AuthGuard />
            <EgretLayout />
            <ToastContainer autoClose={3000} />
          </Router>
        </EgretTheme>
      </Provider>
    </AppContext.Provider>
  );
};

setupInterceptors(store);

export default App;
