import { takeLatest, call, put, all } from "redux-saga/effects";
import * as types from "./types";
import { setUsersData } from "./actions";
import api from "app/services/api";
import ToastService from "../../../services/ToastService";

export function* create({ payload }) {
  const { formData, callback } = payload;
  try {
    yield put({
      type: types.CREATE,
    });
    yield call(api.post, "/users", formData);
    yield put({
      type: types.CREATE_SUCCESS,
    });
    ToastService.showSuccess("usersList.successCreate");
    callback();
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.CREATE_FAIL,
    });
  }
}

export function* read() {
  try {
    yield put({
      type: types.READ,
    });
    const { data } = yield call(api.get, "/users");
    yield put(setUsersData(data));
    yield put({
      type: types.READ_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.READ_FAIL,
    });
  }
}

export function* update({ payload }) {
  const { id, formData, callback } = payload;
  try {
    yield put({
      type: types.UPDATE,
    });
    yield call(api.put, `/users/${id}`, formData);
    yield put({
      type: types.UPDATE_SUCCESS,
    });
    ToastService.showSuccess("Usuário atualizado com sucesso!");
    callback();
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.UPDATE_FAIL,
    });
  }
}

export function* inactivate({ payload }) {
  const { id, callback } = payload;
  try {
    yield put({
      type: types.DELETE,
    });
    yield call(api.delete, `/users/${id}`);
    yield put({
      type: types.DELETE_SUCCESS,
    });
    ToastService.showSuccess("Usuário intivado com sucesso!");
    callback();
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.DELETE_FAIL,
    });
    callback();
  }
}

export default all([
  takeLatest(types.REQUEST_CREATE, create),
  takeLatest(types.REQUEST_READ, read),
  takeLatest(types.REQUEST_UPDATE, update),
  takeLatest(types.REQUEST_DELETE, inactivate),
]);
