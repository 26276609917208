import * as types from './types';

export const setClientsData = (responseData) => ({
  type: types.SET_DATA,
  payload: responseData,
});

export const createClient = ({ name, remote_connections_limit }, callback = () => {}) => ({
  type: types.REQUEST_CREATE,
  payload: { name, remote_connections_limit, callback }
});

export const getClients = (options) => ({
  type: types.REQUEST_READ,
  payload: options,
});

export const updateClient = (formData, callback = () => {}) => ({
  type: types.REQUEST_UPDATE,
  payload: { formData, callback }
});

export const requestUpdateClientApiKey = (apiKey) => ({
  type: types.REQUEST_UPDATE_CLIENT_API_KEY,
  payload: apiKey,
});

export const inactivateClient = (id, callback = () => {}) => ({
  type: types.REQUEST_DELETE,
  payload: { id, callback }
});

export const updateRemoteConnections = (formData) => ({
  type: types.UPDATE_REMOTE_CONNECTIONS,
  payload: { formData },
});
