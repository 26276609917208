export const SET_DATA = "@DEVICE_IMPORT/SET_DATA";
export const SET_FORMATTED = "@DEVICE_IMPORT/SET_FORMATTED";
export const READ = "@DEVICE_IMPORT/READ";
export const READ_FAIL = "@DEVICE_IMPORT/READ_FAIL";
export const READ_SUCCESS = "@DEVICE_IMPORT/READ_SUCCESS";
export const REQUEST_READ = "@DEVICE_IMPORT/REQUEST_READ";
export const CREATE = "@DEVICE_IMPORT/CREATE";
export const REQUEST_CREATE = "@DEVICE_IMPORT/REQUEST_CREATE";
export const CREATE_SUCCESS = "@DEVICE_IMPORT/CREATE_SUCCESS";
export const CREATE_FAIL = "@DEVICE_IMPORT/CREATE_FAIL";
