import React from "react";
import { Redirect } from "react-router-dom";

/*
  Required if you are going to use Evelynn Landing page
  https://themeforest.net/item/react-landing-material-ui-react-saasproduct-landing-page/23847400
*/

// INTEGRAFÁCIL
import clientsListRoute from "./views/@interlaken/clientsList/ClientsListRoute";
import subclientsListRoute from "./views/@interlaken/subclientsList/SubclientsListRoute";
import remoteConnectionsRoute from "./views/@interlaken/remoteConnections/RemoteConnectionsRoute";

import usersListRoute from "./views/@interlaken/usersList/UsersListRoute";
import localesListRoute from "./views/@interlaken/localesList/LocalesListRoute";
import devicesRoute from "./views/@interlaken/Devices/DevicesRoute";
import ifDashboardRoutes from "./views/@interlaken/Dashboard/DashboardRoute";
import HomeRoutes from "./views/@interlaken/Home/HomeRoute";
import userProfileRoutes from "./views/@interlaken/UserProfile/ProfileRoute";
import sessionRoutes from "./views/@interlaken/Sessions/SessionRoutes";
import deviceTypesRoutes from "./views/@interlaken/DeviceTypes/DeviceTypesRoute";
import deviceModelsRoute from "./views/@interlaken/DeviceModels/DeviceModelsRoute";
import TotalsRoute from "./views/@interlaken/Totals/TotalsRoute";
import RemoteConnectionErrorRoute from "./views/@interlaken/RemoteConnectionError/RemoteConnectionErrorRoute";
import logsRoute from "./views/@interlaken/Logs/LogsRoute";
import importsRoute from "./views/@interlaken/Imports/ImportsRoute";
import performanceRoute from "./views/@interlaken/Performance/PerformanceRoute";
import usersConnectionsRoute from "./views/@interlaken/usersConnections/usersConnectionsRoute";
import alertsRoute from "./views/@interlaken/Alerts/AlertsRoute";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  // custom
  ...HomeRoutes,
  ...ifDashboardRoutes,
  ...localesListRoute,
  ...clientsListRoute,
  ...subclientsListRoute,
  ...usersListRoute,
  ...devicesRoute,
  ...userProfileRoutes,
  ...sessionRoutes,
  ...deviceTypesRoutes,
  ...deviceModelsRoute,
  ...remoteConnectionsRoute,
  ...TotalsRoute,
  ...RemoteConnectionErrorRoute,
  ...logsRoute,
  ...importsRoute,
  ...performanceRoute,
  ...alertsRoute,
  ...usersConnectionsRoute,
  // Must be the last
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
