import { EgretLoadable } from 'egret';
import { authRoles } from '../../../auth/authRoles';


const ClientsList = EgretLoadable({
  loader: () => import('./index')
});

const ClientsListRoute = [
  {
    path: '/clients-list',
    exact: true,
    component: ClientsList,
    auth: authRoles.admin
  }
]

export default ClientsListRoute;
