import { EgretLoadable } from "egret";
import { authRoles } from "../../../auth/authRoles";

const Home = EgretLoadable({
  loader: () => import("./index"),
});

const settings = {
  activeLayout: "interlaken",
  interlakenSettings: {
    topbar: {
      show: true,
    },
    leftSidebar: {
      show: true,
    },
  },
  // layout1Settings: {
  //   topbar: {
  //     show: false
  //   },
  //   leftSidebar: {
  //     show: false,
  //     mode: "close"
  //   }
  // },
  // layout2Settings: {
  //   mode: "full",
  //   topbar: {
  //     show: false
  //   },
  //   navbar: { show: false }
  // },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const HomeRoute = [
  {
    path: "/home",
    exact: true,
    component: Home,
    auth: authRoles.all,
    settings,
  },
];

export default HomeRoute;
