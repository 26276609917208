import produce from "immer";
import * as types from "./types";
import jwtAuthService from "../../../services/jwtAuthService";

const is_authenticated = !!jwtAuthService.getSessionData();

const INITIAL_STATE = {
  update_loading: false,
  update_success: false,
  data: is_authenticated ? { ...jwtAuthService.getSessionData() } : {},
  is_authenticated,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SESSION_DATA: {
      return produce(state, (draft) => {
        draft.is_authenticated = true;
        draft.data = { ...action.payload };
      });
    }
    case types.UPDATE:
      return produce(state, (draft) => {
        draft.update_loading = true;
      });
    case types.UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.update_loading = false;
        draft.update_success = true;
      });
    case types.UPDATE_FAIL:
      return produce(state, (draft) => {
        draft.update_loading = false;
        draft.update_success = false;
      });
    case types.REQUEST_LOGOUT:
      return produce(state, (draft) => {
        draft = {
          ...state,
          update_loading: true,
        };
      });
    case types.USER_LOGGED_OUT:
      return produce(state, (draft) => {
        draft.is_authenticated = false;
        draft.data = {};
      });
    default:
      return state;
  }
};

export default userReducer;
