import { takeLatest, put, all, call, race, delay } from "redux-saga/effects";
import history from "history.js";
import * as types from "./types";
import api from "../../../../app/services/api";
import { loginSuccess, loginError } from "./actions";
import { setSessionData } from "../session/actions";

import jwtAuthService from "app/services/jwtAuthService";
import ToastService from "../../../services/ToastService";

export function* login({ payload }) {
  try {
    const { email, password } = payload;

    const { response, timeout } = yield race({
      response: call(api.post, "/sessions", { email, password }),
      timeout: delay(5000),
    });

    if (timeout) {
      ToastService.showError("apiRequest.timeout");
      yield put(loginError());
      return;
    }

    const { data } = response;

    jwtAuthService.setSessionData(data);

    yield put(setSessionData(data));

    history.push({
      pathname: "/",
    });

    yield put(loginSuccess());
  } catch (error) {
    console.log("login.error");
    yield put(loginError());
    ToastService.showApiRequestError(error);
  }
}

export function* logoutUser() {
  yield put({
    type: types.LOGOUT,
  });
  jwtAuthService.logout();
  history.push({
    pathname: "/session/signin",
  });
  yield put({
    type: types.SUCCESS,
  });
}

export default all([
  takeLatest(types.LOGIN, login),
  takeLatest(types.LOGOUT, logoutUser),
]);
