import * as types from "./types";

export const setUersConnections = (responseData) => ({
  type: types.SET_DATA,
  payload: responseData,
});

export const getUsersConnections = (options) => ({
  type: types.REQUEST_READ,
  payload: options,
});

export const removeUserConnection = (id, callback = () => {}) => ({
  type: types.REQUEST_DELETE,
  payload: { id, callback }
});