import * as types from "./types";

export const setLogsData = (responseData) => ({
  type: types.SET_DATA,
  payload: responseData,
});

export const getLogs = (options) => ({
  type: types.REQUEST_READ,
  payload: options,
});
