import * as types from "./types";

export const setDashboardData = (data) => ({
  type: types.SET_DATA,
  payload: { ...data },
});

export const getDashboardData = (options) => ({
  type: types.REQUEST_READ,
  payload: options,
});

export const changeButtonSelected = (name) => ({
  type: types.CHANGE_BUTTON_SELECTED,
  payload: name,
});
