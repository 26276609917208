import * as types from "./types";

export const loginWithEmailAndPassword = ({ email, password }) => ({
  type: types.LOGIN,
  payload: { email, password },
});

export const loginSuccess = () => ({
  type: types.SUCCESS,
});

export const loginError = () => ({
  type: types.ERROR,
});

export const logout = () => ({
  type: types.LOGOUT,
});
