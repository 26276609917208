import * as types from './types';

export const setLocalesData = (responseData) => ({
  type: types.SET_DATA,
  payload: responseData,
});

export const setLocalesFormatted = (formatted) => ({
  type: types.STATUS_UPDATE,
  payload: formatted,
});

export const createLocale = (formData, callback = () => {}) => ({
  type: types.REQUEST_CREATE,
  payload: { formData, callback }
});

export const getLocales = (options) => ({
  type: types.REQUEST_READ,
  payload: options,
});

export const updateLocale = ({ id, ...formData }, callback = () => {}) => ({
  type: types.REQUEST_UPDATE,
  payload: { id, formData, callback }
});

export const inactivateLocale = (id, callback = () => {}) => ({
  type: types.REQUEST_DELETE,
  payload: { id, callback }
});
