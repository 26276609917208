import { takeLatest, call, put, all } from "redux-saga/effects";
import * as types from "./types";
import { setClientsData } from "./actions";
import api from "app/services/api";
import objectToQueryString from "app/utils/objectToQueryString";
import ToastService from "../../../services/ToastService";

export function* create({ payload }) {
  const { name, remote_connections_limit, callback } = payload;
  try {
    yield put({
      type: types.CREATE,
    });
    yield call(api.post, "/clients", { name, remote_connections_limit });
    ToastService.showSuccess("clientsList.successCreate");
    yield put({
      type: types.CREATE_SUCCESS,
    });
    callback();
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.CREATE_FAIL,
    });
  }
}

export function* read({ payload }) {
  try {
    yield put({
      type: types.READ,
    });
    const { data } = yield call(
      api.get,
      `/clients${objectToQueryString(payload)}`
    );
    yield put(setClientsData(data));
    yield put({
      type: types.READ_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.READ_FAIL,
    });
  }
}

export function* update({ payload }) {
  try {
    const { formData, callback } = payload;
    const { id, apiKey, ...rest } = formData;
    yield put({
      type: types.UPDATE,
    });
    if (apiKey) {
      yield call(api.put, `/apikeys/${apiKey.id}`, { api_key: apiKey.api_key });
    }
    yield call(api.put, `/clients/${id}`, { ...rest });
    ToastService.showSuccess("clientsList.successUpdate");
    yield put({
      type: types.UPDATE_SUCCESS,
    });
    callback();
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.UPDATE_FAIL,
    });
  }
}

export function* inactivate({ payload }) {
  const { id, callback } = payload;
  try {
    yield put({
      type: types.DELETE,
    });
    yield call(api.delete, `/clients/${id}`);
    ToastService.showSuccess("clientsList.successInactivate");
    yield put({
      type: types.DELETE_SUCCESS,
    });
    callback();
  } catch (error) {
    yield put({
      type: types.DELETE_FAIL,
    });
    ToastService.showApiRequestError(error);
    callback();
  }
}

export function* updateRemoteConnections({ payload }) {
  const { formData } = payload;
  try {
    yield put({
      type: types.REQUEST_UPDATE_REMOTE_CONNECTIONS,
    });
    yield call(api.put, `/subclients/remote_connections`, formData);
    ToastService.showSuccess("clientsList.successUpdateConnections");
    yield put({
      type: types.REQUEST_UPDATE_REMOTE_CONNECTIONS_SUCCESS,
    });
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.REQUEST_UPDATE_REMOTE_CONNECTIONS_SUCCESS,
    });
  }
}

export function* updateApiKey({ payload }) {
  try {
    const { id, api_key } = payload;
    yield call(api.put, `/apikeys/${id}`, { api_key });
  } catch (error) {
    ToastService.showApiRequestError(error);
  }
}

export default all([
  takeLatest(types.REQUEST_CREATE, create),
  takeLatest(types.REQUEST_READ, read),
  takeLatest(types.REQUEST_UPDATE, update),
  takeLatest(types.REQUEST_DELETE, inactivate),
  takeLatest(types.REQUEST_UPDATE_CLIENT_API_KEY, updateApiKey),
  takeLatest(types.UPDATE_REMOTE_CONNECTIONS, updateRemoteConnections),
]);
