import * as types from "./types";

export const setImportsData = (responseData) => ({
  type: types.SET_DATA,
  payload: responseData,
});

export const getImports = (options) => ({
  type: types.REQUEST_READ,
  payload: options,
});

export const requestImport = (importData) => ({
  type: types.REQUEST_CREATE,
  payload: { importData: importData },
});

export const loadImport = () => ({
  type: types.CREATE,
});

export const finishImport = () => ({
  type: types.CREATE_SUCCESS,
});

export const failImport = () => ({
  type: types.CREATE_FAIL,
});
