import { EgretLoadable } from "egret";
import { authRoles } from "~/app/auth/authRoles";

const Performance = EgretLoadable({
  loader: () => import("./index"),
});

const DevicesRoute = [
  {
    path: "/performance",
    exact: true,
    component: Performance,
    auth: authRoles.all,
  },
];

export default DevicesRoute;
