import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthGuard = ({ children }) => {
  const is_authenticated = useSelector((state) => {
    return state.session.is_authenticated;
  });
  return !is_authenticated ? <Redirect to="/session/signin" /> : null;
};

export default AuthGuard;
