import { takeLatest, call, put, all } from "redux-saga/effects";
import * as types from "./types";
import { setUersConnections } from "./actions";
import api from "app/services/api";
import ToastService from "../../../services/ToastService";

export function* read({ payload }) {
  try {
    yield put({
      type: types.READ,
    });
    const { data } = yield call(api.get, "/users-tokens", {
      params: payload,
    });
    yield put(setUersConnections(data));
    yield put({
      type: types.READ_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.READ_FAIL,
    });
  }
}

export function* remove({ payload }) {
  const { id, callback } = payload;
  try {
    yield put({
      type: types.DELETE,
    });
    yield call(api.delete, `/users-tokens/${id}`);
    ToastService.showSuccess("usersConnectionsList.successInactivate");
    yield put({
      type: types.DELETE_SUCCESS,
    });
    callback();
  } catch (error) {
   yield put({
      type: types.DELETE_FAIL,
    });
    ToastService.showApiRequestError(error);
    callback();
  }
}

export default all([
  takeLatest(types.REQUEST_READ, read),
  takeLatest(types.REQUEST_DELETE, remove),
]);
