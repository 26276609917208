import { all, call, takeLatest, put } from "redux-saga/effects";
import * as types from "./types";
import { setDashboardData } from "./actions";

import api from "app/services/api";

export function* read({ payload }) {
  try {
    yield put({
      type: types.READ,
    });
    const { data } = yield call(api.get, "/dashboard", { params: payload });
    yield put(setDashboardData(data));
    yield put({
      types: types.READ_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.READ_FAIL,
    });
  }
}

export default all([takeLatest(types.REQUEST_READ, read)]);
