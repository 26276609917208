import axiosInstance from "./api";
import JwtAuthService from "./jwtAuthService";
import { logoutUser } from "../store/modules/session/actions";

const setup = (store) => {
  let authTokenRequest;

  function refreshToken() {
    return axiosInstance.post("/sessions/refresh", {
      refreshToken: JwtAuthService.getLocalRefreshToken(),
    });
  }

  function resetAuthTokenRequest(data) {
    authTokenRequest = null;
  }
  // This function makes a call to get the auth token
  // or it returns the same promise as an in-progress call to get the auth token
  async function getAuthToken() {
    if (!authTokenRequest) {
      authTokenRequest = refreshToken();
      authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
    }

    return authTokenRequest;
  }

  axiosInstance.interceptors.request.use(
    (config) => {
      const data = JwtAuthService.getSessionData();
      if (data && data.token) {
        config.headers["Authorization"] = `Bearer ${data.token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (!originalConfig.url.startsWith("/sessions") && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          return getAuthToken()
            .then(({ data }) => {
              JwtAuthService.setSessionData(data);
              originalConfig._retry = true;
              return axiosInstance(originalConfig);
            })
            .catch((err) => {
              dispatch(logoutUser());
              JwtAuthService.logout();
            });
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
