import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  test: 'value',
  data: [{
    id: 1,
    heading: 'Hey',
    title: 'Title',
    subtitle: 'subtitle',
    path: '/dashboard',
    timestamp: new Date(),
    icon: {
      color: 'primary',
      name: 'check'
    }
  }]
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET:
      return produce(state, draft => {
        // draft.data = [];
      });
    case types.CREATE:
      return produce(state, draft => {
        draft.data = [ ...action.payload ];
      });
    case types.DELETE:
      return produce(state, draft => {
        draft.data = [ ...action.payload ];
      });
    case types.DELETE_ALL:
      return produce(state, draft => {
        draft.data = [ ...action.payload ];
      });
    default:
      return state;
  }
};

export default notificationReducer;
