import interlakenSettings from './@InterlakenLayout/InterlakenSettings';
import { themeColors } from "./EgretTheme/themeColors";
import { createTheme } from '@material-ui/core/styles'
import { forEach, merge } from "lodash";
import themeOptions from "./EgretTheme/themeOptions";

function createEgretThemes() {
  let themes = {};

  forEach(themeColors, (value, key) => {
    themes[key] = createTheme(merge({}, themeOptions, value));
  });
  return themes;
}
const themes = createEgretThemes();

export const EgretLayoutSettings = {
  // activeLayout: "layout1", // layout1, layout2
  // activeTheme: "purple1", // View all valid theme colors inside EgretTheme/themeColors.js
  activeLayout: "interlaken", // layout1, layout2
  activeTheme: "blue", // View all valid theme colors inside EgretTheme/themeColors.js
  perfectScrollbar: true,

  themes: themes,
  interlakenSettings,
  // layout1Settings, // open Layout1/Layout1Settings.js
  // layout2Settings, // open Layout1/Layout2Settings.js

  secondarySidebar: {
    show: false,
    theme: "slateDark1" // View all valid theme colors inside EgretTheme/themeColors.js
  },
  // Footer options
  footer: {
    show: false,
    fixed: false,
    theme: "slateDark1" // View all valid theme colors inside EgretTheme/themeColors.js
  }
};
