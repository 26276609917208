import { all } from "redux-saga/effects";

import notification from "./notification/sagas";
import subclients from "./subclients/sagas";
import dashboard from "./dashboard/sagas";
import clients from "./clients/sagas";
import locales from "./locales/sagas";
import layout from "./layout/sagas";
import login from "./login/sagas";
import session from "./session/sagas";
import users from "./users/sagas";
import deviceImport from "./deviceImport/sagas";

import devices from "./devices/sagas";
import deviceTypes from "./deviceTypes/sagas";
import deviceModels from "./deviceModels/sagas";
import remoteConnections from "./remoteConnections/sagas";
import logs from "./logs/sagas";
import apikeys from "./apikeys/sagas";
import importsTracer from "./importsTracer/sagas";
import usersConnections from './usersConnections/sagas';

export default function* rootSaga() {
  return yield all([
    notification,
    subclients,
    dashboard,
    locales,
    clients,
    layout,
    login,
    session,
    users,
    devices,
    deviceTypes,
    deviceModels,
    deviceImport,
    remoteConnections,
    apikeys,
    logs,
    importsTracer,
    usersConnections,
  ]);
}
