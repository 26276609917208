import { EgretLoadable } from 'egret';
import { authRoles } from 'app/auth/authRoles';

const RemoteConnectionError = EgretLoadable({
  loader: () => import('./index')
});

const settings = {
  activeLayout: "interlaken",
  interlakenSettings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

const RemoteConnectionRoute = [
  {
    path: '/remote_error',
    exact: true,
    component: RemoteConnectionError,
    auth: authRoles.subclient,
    settings
  }
];

export default RemoteConnectionRoute;
