import { EgretLoadable } from 'egret';
import { authRoles } from '../../../auth/authRoles';

const Totals = EgretLoadable({
  loader: () => import('./index')
});

const UsersListRoute = [
  {
    path: '/totals',
    exact: true,
    component: Totals,
    auth: authRoles.all,
  }
];

export default UsersListRoute;
