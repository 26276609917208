import { put, all, takeEvery } from 'redux-saga/effects';
import * as types from './types';

export function* layoutSaga({ payload }) {
  yield put({
    type: types.SET_LAYOUT_SETTINGS,
    payload,
  });
}

export default all([
  takeEvery(types.REQUEST_LAYOUT_SETTINGS_CHANGE, layoutSaga),
]);