export default function objectToQueryString(object) {
  if (!Object.keys(object)) return;
  const keys = Object.keys(object);
  const values = Object.values(object);
  if (keys.length !== values.length) throw new Error('Bad format');
  let queryString = '?';
  for (let i = 0; i < keys.length; i++) {
    queryString += `${keys[i]}=${values[i]}&`;
  }
  return queryString;
}