export const SET_DATA = '@SUBCLIENTS/SET_DATA';
export const ADD = '@SUBCLIENTS/ADD';
export const READ = '@SUBCLIENTS/READ';
export const READ_FAIL = '@SUBCLIENTS/READ_FAIL';
export const READ_SUCCESS = '@SUBCLIENTS/READ_SUCCESS';
export const CREATE = '@SUBCLIENTS/CREATE';
export const CREATE_FAIL = '@SUBCLIENTS/CREATE_FAIL';
export const CREATE_SUCCESS = '@SUBCLIENTS/CREATE_SUCCESS';
export const UPDATE = '@SUBCLIENTS/UPDATE';
export const UPDATE_FAIL = '@SUBCLIENTS/UPDATE_FAIL';
export const UPDATE_SUCCESS = '@SUBCLIENTS/UPDATE_SUCCESS';
export const DELETE = '@SUBCLIENTS/DELETE';
export const DELETE_FAIL = '@SUBCLIENTS/DELETE_FAIL';
export const DELETE_SUCCESS = '@SUBCLIENTS/DELETE_SUCCESS';
export const REQUEST_READ = '@SUBCLIENTS/REQUEST_READ';
export const REQUEST_CREATE = '@SUBCLIENTS/REQUEST_CREATE';
export const REQUEST_UPDATE = '@SUBCLIENTS/REQUEST_UPDATE';
export const REQUEST_DELETE = '@SUBCLIENTS/REQUEST_DELETE';
export const REQUEST_UPDATE_SUBCLIENT_API_KEY = '@SUBCLIENTS/REQUEST_UPDATE_API_KEY';