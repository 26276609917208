import { EgretLoadable } from 'egret';
import { authRoles } from 'app/auth/authRoles';

const LocaleList = EgretLoadable({
  loader: () => import('./index')
});

const LocaleListRoute = [
  {
    path: '/locales-list',
    exact: true,
    component: LocaleList,
    auth: authRoles.subclient,
  }
];

export default LocaleListRoute;
