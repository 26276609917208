import { EgretLoadable } from 'egret';
import { authRoles } from '~/app/auth/authRoles';

const Devices = EgretLoadable({
  loader: () => import('./index')
});

const DevicesRoute = [
  {
    path: '/devices',
    exact: true,
    component: Devices,
    auth: authRoles.all,
  }
];

export default DevicesRoute;
