import * as types from "./types";

export const setDeviceTypesData = (devices) => ({
  type: types.SET_DATA,
  payload: devices,
});

export const createDeviceType = (formData, callback = () => {}) => ({
  type: types.REQUEST_CREATE,
  payload: { formData, callback },
});

export const getDeviceTypes = (options) => ({
  type: types.REQUEST_READ,
  payload: options,
});

export const updateDeviceType = ({ id, ...formData }, callback = () => {}) => ({
  type: types.REQUEST_UPDATE,
  payload: { id, formData, callback },
});

export const inactivateDeviceType = (id, callback = () => {}) => ({
  type: types.REQUEST_DELETE,
  payload: { id, callback },
});
