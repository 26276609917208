export const CREATE = '@REMOTE_CONNECTION/CREATE';
export const CREATE_SUCCESS = '@REMOTE_CONNECTION/CREATE_SUCCESS';
export const CREATE_FAIL = '@REMOTE_CONNECTION/CREATE_FAIL';

export const DELETE = '@REMOTE_CONNECTION/DELETE';
export const DELETE_SUCCESS = '@REMOTE_CONNECTION/DELETE_SUCCESS';
export const DELETE_FAIL = '@REMOTE_CONNECTION/DELETE_FAIL';

export const ADD_REMOTE_CONNECTION = '@REMOTE_CONNECTION/ADD';
export const REMOVE_REMOTE_CONNECTION = '@REMOTE_CONNECTION/REMOVE';
export const REQUEST_ADD_REMOTE_CONNECTION = '@REMOTE_CONNECTION/REQUEST_ADD';
export const REQUEST_REMOVE_REMOTE_CONNECTION = '@REMOTE_CONNECTION/REQUEST_REMOVE';