import produce from "immer";
import * as types from "./types";

const INITIAL_STATE = {
  read_loading: false,
  read_success: false,
  data: [],
  meta: {
    page: 1,
    per_page: 10,
    pre_page: null,
    next_page: 2,
    total: 4573,
    total_pages: 458,
  },
};

const logsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_DATA:
      return produce(state, (draft) => {
        const { result, ...rest } = action.payload;
        draft.data = result;
        draft.meta = rest;
      });
    case types.SET_FORMATTED:
      return produce(state, (draft) => {
        draft.formatted = { ...action.payload };
      });
    case types.READ:
      return produce(state, (draft) => {
        draft.read_loading = true;
      });
    case types.READ_SUCCESS:
      return produce(state, (draft) => {
        draft.read_loading = false;
        draft.read_success = true;
      });
    case types.READ_FAIL:
      return produce(state, (draft) => {
        draft.read_loading = false;
        draft.read_success = false;
      });
    default:
      return state;
  }
};

export default logsReducer;
