export const SET_DATA = "@DEVICES/SET_DATA";
export const SET_FORMATTED = "@DEVICES/SET_FORMATTED";
export const ADD = "@DEVICES/ADD";
export const READ = "@DEVICES/READ";
export const READ_FAIL = "@DEVICES/READ_FAIL";
export const READ_SUCCESS = "@DEVICES/READ_SUCCESS";

export const READ_DEVICES_WITH_ALERTS = "@DEVICES/READ_DEVICES_WITH_ALERTS";
export const READ_DEVICES_WITH_ALERTS_FAIL =
  "@DEVICES/READ_DEVICES_WITH_ALERTS_FAIL";
export const READ_DEVICES_WITH_ALERTS_SUCCESS =
  "@DEVICES/READ_DEVICES_WITH_ALERTS_SUCCESS";

export const REQUEST_FAVORITE_DEVICES = "@DEVICES/REQUEST_FAVORITE_DEVICES";
export const SET_FAVORITE_DEVICES = "@DEVICES/SET_FAVORITE_DEVICES";
export const READ_FAVORITE_DEVICES = "@DEVICES/READ_FAVORITE_DEVICES";
export const READ_FAVORITE_DEVICES_FAIL = "@DEVICES/READ_FAVORITE_DEVICES_FAIL";
export const READ_FAVORITE_DEVICES_SUCCESS =
  "@DEVICES/READ_FAVORITE_DEVICES_SUCCESS";

export const CREATE = "@DEVICES/CREATE";
export const CREATE_FAIL = "@DEVICES/CREATE_FAIL";
export const CREATE_SUCCESS = "@DEVICES/CREATE_SUCCESS";
export const CREATE_DEVICES_TASK = "@DEVICES/CREATE_DEVICES_TASK";
export const CREATE_DEVICES_TASK_FAIL = "@DEVICES/CREATE_DEVICES_TASK_FAIL";
export const CREATE_DEVICES_TASK_SUCCESS =
  "@DEVICES/CREATE_DEVICES_TASK_SUCCESS";
export const UPDATE = "@DEVICES/UPDATE";
export const UPDATE_FAIL = "@DEVICES/UPDATE_FAIL";
export const UPDATE_SUCCESS = "@DEVICES/UPDATE_SUCCESS";
export const DELETE = "@DEVICES/DELETE";
export const DELETE_FAIL = "@DEVICES/DELETE_FAIL";
export const DELETE_SUCCESS = "@DEVICES/DELETE_SUCCESS";
export const REQUEST_READ = "@DEVICES/REQUEST_READ";

export const REQUEST_DEVICES_WITH_ALERTS =
  "@DEVICES/REQUEST_DEVICES_WITH_ALERTS";
export const SET_DEVICES_WITH_ALERTS = "@DEVICES/SET_DEVICES_WITH_ALERTS";
export const REQUEST_DELETE_FAVORITE_DEVICE =
  "@DEVICES/REQUEST_DELETE_FAVORITE_DEVICE";
export const REQUEST_CREATE_FAVORITE_DEVICE =
  "@DEVICES/REQUEST_CREATE_FAVORITE_DEVICE";
export const REQUEST_DEVICES_TASK_CREATE =
  "@DEVICES/REQUEST_DEVICES_TASK_CREATE";
export const REQUEST_CREATE = "@DEVICES/REQUEST_CREATE";
export const REQUEST_UPDATE = "@DEVICES/REQUEST_UPDATE";
export const REQUEST_DELETE = "@DEVICES/REQUEST_DELETE";
export const REQUEST_UPDATE_STATUS = "@DEVICES/REQUEST_UPDATE_STATUS";
export const UPDATE_STATUS = "@DEVICES/UPDATE_STATUS";
export const UPDATE_FAIL_STATUS = "@DEVICES/UPDATE_STATUS_FAIL";
export const UPDATE_SUCCESS_STATUS = "@DEVICES/UPDATE_STATUS_SUCCESS";
export const REQUEST_ACTIVE_DEVICE_LICENSE =
  "@DEVICES/REQUEST_ACTIVE_DEVICE_LICENSE";
export const REQUEST_BLOCK_IF_AGENT = "@DEVICES/REQUEST_BLOCK_IF_AGENT";
export const REQUEST_UNBLOCK_IF_AGENT = "@DEVICES/REQUEST_UNBLOCK_IF_AGENT";

export const REQUEST_DEVICES_STATISTICS = "@DEVICES/REQUEST_DEVICES_STATISTICS";
export const READ_DEVICES_STATISTICS = "@DEVICES/READ_DEVICES_STATISTICS";
export const SET_DEVICES_STATISTICS = "@DEVICES/SET_DEVICES_STATISTICS";
export const READ_DEVICES_STATISTICS_FAIL =
  "@DEVICES/READ_DEVICES_STATISTICS_FAIL";
export const READ_DEVICES_STATISTICS_SUCCESS =
  "@DEVICES/READ_DEVICES_STATISTICS_SUCCESS";

export const REQUEST_DEVICES_ALERTS = "@DEVICES/REQUEST_DEVICES_ALERTS";
export const READ_DEVICES_ALERTS = "@DEVICES/READ_DEVICES_ALERTS";
export const SET_DEVICES_ALERTS = "@DEVICES/SET_DEVICES_ALERTS";
export const READ_DEVICES_ALERTS_FAIL = "@DEVICES/READ_DEVICES_ALERTS_FAIL";
export const READ_DEVICES_ALERTS_SUCCESS =
  "@DEVICES/READ_DEVICES_ALERTS_SUCCESS";
export const REQUEST_BLOCK_SYSTEM = "@DEVICES/REQUEST_BLOCK_SYSTEM";
export const REQUEST_UNBLOCK_SYSTEM = "@DEVICES/REQUEST_UNBLOCK_SYSTEM";

export const REQUEST_DEVICES_PRODUCTS = "@DEVICES/REQUEST_DEVICES_PRODUCTS";
export const READ_DEVICES_PRODUCTS = "@DEVICES/READ_DEVICES_PRODUCTS";
export const SET_DEVICES_PRODUCTS = "@DEVICES/SET_DEVICES_PRODUCTS";
export const READ_DEVICES_PRODUCTS_FAIL = "@DEVICES/READ_DEVICES_PRODUCTS_FAIL";
export const READ_DEVICES_PRODUCTS_SUCCESS = "@DEVICES/READ_DEVICES_PRODUCTS_SUCCESS";

export const REQUEST_UPDATE_DEVICE_PRODUCT = "@DEVICES/REQUEST_UPDATE_DEVICE_PRODUCT";
export const UPDATE_DEVICE_PRODUCT = "@DEVICES/UPDATE_DEVICE_PRODUCT";
export const UPDATE_DEVICE_PRODUCT_FAIL = "@DEVICES/UPDATE_DEVICE_PRODUCT_FAIL";
export const UPDATE_DEVICE_PRODUCT_SUCCESS = "@DEVICES/UPDATE_DEVICE_PRODUCT_SUCCESS";

export const REQUEST_DEVICE_TASKS = "@DEVICES/REQUEST_DEVICE_TASKS";
export const READ_DEVICE_TASKS = "@DEVICES/READ_DEVICE_TASKS";
export const SET_DEVICE_TASKS = "@DEVICES/SET_DEVICE_TASKS";
export const READ_DEVICE_TASKS_FAIL = "@DEVICES/READ_DEVICE_TASKS_fAIL";
export const READ_DEVICE_TASKS_SUCCESS = "@DEVICES/READ_DEVICE_TASKS_SUCCESS";

export const REQUEST_REACTIVATE = "@DEVICES/REQUEST_REACTIVATE";
export const REACTIVATE = "@DEVICES/REACTIVATE";
export const REACTIVATE_FAIL = "@DEVICES/REACTIVATE_FAIL";
export const REACTIVATE_SUCCESS = "@DEVICES/REACTIVATE_SUCCESS";