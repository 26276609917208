import {
  takeLatest,
  put,
  all,
  call
} from "redux-saga/effects";
import history from "history.js";
import * as types from "./types";
import jwtAuthService from "app/services/jwtAuthService";
import api from "../../../../app/services/api";
import ToastService from "../../../services/ToastService";

function waitToExecute(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function* update({ payload }) {
  try {
    const { formData } = payload;

    const response = yield call(api.put, "/profile", formData);
    const { data } = response;
    console.log(data)
    if (response.status === 200) {
      ToastService.showSuccess("userProfile.successUpdate");
      yield put({
        type: types.UPDATE_SUCCESS,
      });
      yield call(waitToExecute, 3000);
      yield call(logout);
    } else {
      yield put({
        type: types.UPDATE_FAIL,
      });
      ToastService.error(`apiResponse.${data.errorToken}`);
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_FAIL,
    });
    ToastService.showApiRequestError(error);
  }
}

export function* logout() {
  yield jwtAuthService.logout();
  history.push({
    pathname: "/session/signin",
  });
  yield put({
    type: types.USER_LOGGED_OUT,
  });
}

export default all([
  takeLatest(types.UPDATE, update),
  takeLatest(types.REQUEST_LOGOUT, logout),
]);
