import { EgretLoadable } from 'egret';
import { authRoles } from 'app/auth/authRoles';

const RemoteConnections = EgretLoadable({
  loader: () => import('./index')
});

const RemoteConnectionsRoute = [
  {
    path: '/connections',
    exact: true,
    component: RemoteConnections,
    auth: authRoles.client,
  }
];

export default RemoteConnectionsRoute;
