import produce from "immer";
import * as types from "./types";

const INITIAL_STATE = {
  read_loading: false,
  read_success: false,
  data: {
    generalSummary: {
      locales: {
        totalLocales: 0,
        totalLocalesWithoutDevices: 0,
        totalLocalesOffline: 0,
        totalLocalesOnline: 0,
        totalLocalesWarn: 0,
      },
      devices: {
        totalDevices: 0,
        totalDevicesActive: 0,
        totalDevicesActiveON: 0,
        totalDevicesActiveOnWithAlert: 0,
        totalDevicesActiveOFF: 0,
        totalDevicesActiveOffWithAlert: 0,
        totalDevicesActiveNeverSentAPing: 0,
        totalDevicesActiveRented: 0,
        totalDevicesActiveUnowned: 0,
        totalDevicesActiveWithAlert: 0,
        totalDevicesInactive: 0,
      },
    },
  },
  buttonSelected: "devices.totalActiveWithAlert",
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_DATA:
      return produce(state, (draft) => {
        draft.data = action.payload;
      });
    case types.READ:
      return produce(state, (draft) => {
        draft.read_loading = true;
      });
    case types.READ_SUCCESS:
      return produce(state, (draft) => {
        draft.read_loading = false;
        draft.read_success = true;
      });
    case types.READ_FAIL:
      return produce(state, (draft) => {
        draft.read_loading = false;
        draft.read_success = false;
      });
    case types.CHANGE_BUTTON_SELECTED:
      return produce(state, (draft) => {
        draft.buttonSelected = action.payload;
      });
    default:
      return state;
  }
};

export default dashboardReducer;
