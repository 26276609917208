export const SET_DATA = '@DEVICE_TYPES/SET_DATA';
export const ADD = '@DEVICE_TYPES/ADD';
export const READ = '@DEVICE_TYPES/READ';
export const READ_FAIL = '@DEVICE_TYPES/READ_FAIL';
export const READ_SUCCESS = '@DEVICE_TYPES/READ_SUCCESS';
export const CREATE = '@DEVICE_TYPES/CREATE';
export const CREATE_FAIL = '@DEVICE_TYPES/CREATE_FAIL';
export const CREATE_SUCCESS = '@DEVICE_TYPES/CREATE_SUCCESS';
export const UPDATE = '@DEVICE_TYPES/UPDATE';
export const UPDATE_FAIL = '@DEVICE_TYPES/UPDATE_FAIL';
export const UPDATE_SUCCESS = '@DEVICE_TYPES/UPDATE_SUCCESS';
export const DELETE = '@DEVICE_TYPES/DELETE';
export const DELETE_FAIL = '@DEVICE_TYPES/DELETE_FAIL';
export const DELETE_SUCCESS = '@DEVICE_TYPES/DELETE_SUCCESS';
export const REQUEST_READ = '@DEVICE_TYPES/REQUEST_READ';
export const REQUEST_CREATE = '@DEVICE_TYPES/REQUEST_CREATE';
export const REQUEST_UPDATE = '@DEVICE_TYPES/REQUEST_UPDATE';
export const REQUEST_DELETE = '@DEVICE_TYPES/REQUEST_DELETE';