import * as types from "./types";

export const setSessionData = (data) => ({
  type: types.SET_SESSION_DATA,
  payload: { ...data },
});

export const updateUser = (formData) => ({
  type: types.UPDATE,
  payload: { formData },
});

export const updateUserSuccess = (user) => ({
  type: types.UPDATE_SUCCESS,
  payload: { ...user },
});

export const logoutUser = () => ({
  type: types.REQUEST_LOGOUT,
});
