import { EgretLoadable } from 'egret';
import { authRoles } from '~/app/auth/authRoles';

const DeviceTypes = EgretLoadable({
  loader: () => import('./index')
});

const DeviceTypesRoute = [
  {
    path: '/device-types',
    exact: true,
    component: DeviceTypes,
    auth: authRoles.admin,
  }
];

export default DeviceTypesRoute;
