import produce from 'immer';
import * as types from './types';
import { EgretLayoutSettings } from 'app/EgretLayout/settings';

const INITIAL_STATE = {
  settings: {
    ...EgretLayoutSettings
  },
};

const LayoutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_LAYOUT_SETTINGS:
      return produce(state, draft => {
        draft.settings = action.payload;
      });
    default:
      return state;
  }
};

export default LayoutReducer;