import { EgretLoadable } from "egret";
import { authRoles } from "~/app/auth/authRoles";

const Alerts = EgretLoadable({
  loader: () => import("./index"),
});

const DevicesRoute = [
  {
    path: "/alerts",
    exact: true,
    component: Alerts,
    auth: authRoles.all,
  },
];

export default DevicesRoute;
