import * as types from './types';

export const setSubclientsData = (responseData) => ({
  type: types.SET_DATA,
  payload: responseData,
});

export const createSubclient = (formData, callback = () => {}) => ({
  type: types.REQUEST_CREATE,
  payload: { formData, callback }
});

export const getSubclients = (options) => ({
  type: types.REQUEST_READ,
  payload: options,
});

export const requestUpdateSubclientApiKey = (apiKey) => ({
  type: types.REQUEST_UPDATE_SUBCLIENT_API_KEY,
  payload: apiKey,
});

export const updateSubclient = ({ id, ...formData }, callback = () => {}) => ({
  type: types.REQUEST_UPDATE,
  payload: { id, formData, callback }
});

export const inactivateSubclient = (id, callback = () => {}) => ({
  type: types.REQUEST_DELETE,
  payload: { id, callback }
});
