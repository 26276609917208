import { takeLatest, call, put, all } from "redux-saga/effects";
import * as types from "./types";
import { setImportsTracerData } from "./actions";
import api from "app/services/api";
import objectToQueryString from "app/utils/objectToQueryString";
import ToastService from "../../../services/ToastService";

export function* read({ payload }) {
  try {
    yield put({
      type: types.READ,
    });
    const { data } = yield call(
      api.get,
      `/imports${objectToQueryString(payload)}`
    );

    yield put(setImportsTracerData(data.result));
    yield put({
      type: types.READ_SUCCESS,
    });
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({
      type: types.READ_FAIL,
    });
  }
}

export default all([takeLatest(types.REQUEST_READ, read)]);
