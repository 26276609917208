import * as types from "./types";

export const setImportsTracerData = (responseData) => ({
  type: types.SET_DATA,
  payload: responseData,
});

export const getImportsTracerdata = (options) => ({
  type: types.REQUEST_READ,
  payload: options,
});
