import React, { Component } from "react";
import {
  Card,
  Button,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { loginWithEmailAndPassword } from "../../../store/modules/login/actions";

const styles = (theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class SignIn extends Component {
  state = {
    email: "",
    password: "",
    agreement: "",
  };

  handleChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleFormSubmit = (event) => {
    this.props.loginWithEmailAndPassword({ ...this.state });
  };
  render() {
    let { email, password } = this.state;
    let { classes, t } = this.props;
    return (
      <div className="signup flex flex-center  h-100vh">
        <div className="p-8 signup-content ">
          <Card className="signup-card ">
            <div className="flex flex-center mt-16">
              <img src="/assets/images/logo.png" alt="" />
            </div>
            <div className="p-36">
              <ValidatorForm
                autoComplete="off"
                ref="form"
                onSubmit={this.handleFormSubmit}
              >
                <TextValidator
                  fullWidth
                  className="mb-24 capitalize"
                  variant="outlined"
                  label={t("words.email")}
                  onChange={this.handleChange}
                  type="email"
                  name="email"
                  value={email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    t("components.inputForm.requiredFieldError"),
                    t("components.inputForm.emailFieldError"),
                  ]}
                />
                <TextValidator
                  fullWidth
                  className="mb-16 capitalize"
                  label={t("words.password")}
                  variant="outlined"
                  onChange={this.handleChange}
                  name="password"
                  type="password"
                  value={password}
                  validators={["required"]}
                  errorMessages={[t("components.inputForm.requiredFieldError")]}
                />

                <div className="flex mb-8">
                  <div className={classes.wrapper}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={this.props.login.loading}
                      type="submit"
                    >
                      {t("signIn.loginButton")}
                    </Button>
                    {this.props.login.loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
                {/* <Button
                  className="text-primary"
                  onClick={() =>
                    this.props.history.push("/session/forgot-password")
                  }
                >
                  {t("signIn.forgotPasswordButton")}
                </Button> */}
              </ValidatorForm>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const SignInWithTranslation = withTranslation()(SignIn);

const mapStateToProps = (state) => ({
  login: state.login,
});

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, { loginWithEmailAndPassword })(
      SignInWithTranslation
    )
  )
);
