import * as types from "./types";

export const setDevicesData = (responseData) => ({
  type: types.SET_DATA,
  payload: responseData,
});

export const createDevice = (formData, callback = () => {}) => ({
  type: types.REQUEST_CREATE,
  payload: { formData, callback },
});

export const createDevicesTask = (formData, callback = () => {}) => ({
  type: types.REQUEST_DEVICES_TASK_CREATE,
  payload: { formData, callback },
});

export const getDevices = (options) => ({
  type: types.REQUEST_READ,
  payload: options,
});

export const getFavoriteDevices = (options) => ({
  type: types.REQUEST_FAVORITE_DEVICES,
  payload: options,
});

export const getDevicesWithAlerts = (options) => ({
  type: types.REQUEST_DEVICES_WITH_ALERTS,
  payload: options,
});

export const deleteFavoriteDevice = (id, callback = () => {}) => ({
  type: types.REQUEST_DELETE_FAVORITE_DEVICE,
  payload: { id, callback },
});

export const createFavoriteDevice = (options) => ({
  type: types.REQUEST_CREATE_FAVORITE_DEVICE,
  payload: options,
});

export const updateDevice = ({ id, ...formData }, callback = () => {}) => ({
  type: types.REQUEST_UPDATE,
  payload: { id, formData, callback },
});

export const inactivateDevice = (id, callback = () => {}) => ({
  type: types.REQUEST_DELETE,
  payload: { id, callback },
});

export const updateDeviceStatus = (serial_number) => ({
  type: types.REQUEST_UPDATE_STATUS,
  payload: { serial_number },
});

export const activeteDeviceLicense = (serial_number, license_type) => ({
  type: types.REQUEST_ACTIVE_DEVICE_LICENSE,
  payload: { serial_number, license_type },
});

export const blockIFAgent = (serial_number) => ({
  type: types.REQUEST_BLOCK_IF_AGENT,
  payload: { serial_number },
});

export const unblockIFAgent = (serial_number) => ({
  type: types.REQUEST_UNBLOCK_IF_AGENT,
  payload: { serial_number },
});

export const getDevicesStatistics = (options) => ({
  type: types.REQUEST_DEVICES_STATISTICS,
  payload: options,
});

export const getDevicesAlerts = (options) => ({
  type: types.REQUEST_DEVICES_ALERTS,
  payload: options,
});

export const blockSystem = (serial_number) => ({
  type: types.REQUEST_BLOCK_SYSTEM,
  payload: { serial_number },
});

export const unblockSystem = (serial_number) => ({
  type: types.REQUEST_UNBLOCK_SYSTEM,
  payload: { serial_number },
});

export const getDevicesProducts = (options) => ({
  type: types.REQUEST_DEVICES_PRODUCTS,
  payload: options,
});
export const updateDeviceProduct = (formData, callback = () => {}) => ({
  type: types.REQUEST_UPDATE_DEVICE_PRODUCT,
  payload: { formData, callback },
});
export const getDeviceTasks = (options) => ({
  type: types.REQUEST_DEVICE_TASKS,
  payload: options,
});

export const reactivateDevice = (id, callback = () => {}) => ({
  type: types.REQUEST_REACTIVATE,
  payload: { id, callback },
});