import "babel-polyfill";
// import cssVars from "css-vars-ponyfill";
import * as Sentry from "@sentry/react";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./_index.scss";

import * as serviceWorker from "./serviceWorker";
import "./i18n";
import App from "./app/App";

const dsn =
  process.env.NODE_ENV === "production"
    ? "https://090f13f34bc7493085898b4a023d834b@o313895.ingest.sentry.io/5951169"
    : "https://bc512f97430f47f69c04f6a38436e002@o313895.ingest.sentry.io/5951165";

Sentry.init({
  dsn,
});

ReactDOM.render(
  <Suspense fallback="...">
    <App />
  </Suspense>,
  document.getElementById("root")
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in EgretTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
