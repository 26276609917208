import * as types from "./types";

export const setDeviceModelsData = (devices) => ({
  type: types.SET_DATA,
  payload: devices,
});

export const createDeviceModel = (formData, callback = () => {}) => ({
  type: types.REQUEST_CREATE,
  payload: { formData, callback },
});

export const getDeviceModels = (options) => ({
  type: types.REQUEST_READ,
  payload: options,
});

export const updateDeviceModel = (
  { id, ...formData },
  callback = () => {}
) => ({
  type: types.REQUEST_UPDATE,
  payload: { id, formData, callback },
});

export const inactivateDeviceModel = (id, callback = () => {}) => ({
  type: types.REQUEST_DELETE,
  payload: { id, callback },
});
