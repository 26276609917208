import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;

export const fetchApi = (url, method, data) => {
  const token = localStorage.getItem("jwt_token");
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method: method ? method.toUpperCase() : "",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data || {}),
  });
};
