import { takeLatest, call, put, all } from "redux-saga/effects";
import * as types from "./types";
import api from "app/services/api";
import ToastService from "../../../services/ToastService";

export function* addRemoteConnection({ payload }) {
  try {
    const { serialNumber } = payload;
    yield put({ type: types.CREATE });
    const { data } = yield call(
      api.get,
      `/devices/startremote/${serialNumber}`
    );
    if (data.port === 0) throw new Error("No connection");
    yield put({
      type: types.ADD_REMOTE_CONNECTION,
      payload: { ...data, serialNumber },
    });
    yield put({ type: types.CREATE_SUCCESS });
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({ type: types.CREATE_FAIL });
  }
}

export function* removeRemoteConnection({ payload }) {
  try {
    const { connection_id } = payload;
    yield put({ type: types.DELETE });
    yield call(api.get, `devices/stopremote/${connection_id}`);
    yield put({
      type: types.REMOVE_REMOTE_CONNECTION,
      payload: connection_id,
    });
    yield put({ type: types.DELETE_SUCCESS });
  } catch (error) {
    ToastService.showApiRequestError(error);
    yield put({ type: types.DELETE_FAIL });
  }
}

export default all([
  takeLatest(types.REQUEST_ADD_REMOTE_CONNECTION, addRemoteConnection),
  takeLatest(types.REQUEST_REMOVE_REMOTE_CONNECTION, removeRemoteConnection),
]);
