import produce from "immer";
import * as types from "./types";

const INITIAL_STATE = {
  create_loading: false,
  create_success: false,
  read_loading: false,
  read_success: false,
  update_loading: false,
  update_success: false,
  delete_loading: false,
  delete_success: false,
  remoteConnections_update_loading: false,
  data: [],
  meta: {
    page: 1,
    per_page: 10,
    pre_page: null,
    next_page: 2,
    total: 4573,
    total_pages: 458,
  },
};

const clientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD:
      return produce(state, (draft) => {
        draft.data = [...state.data, { ...action.payload }];
      });
    case types.SET_DATA:
      return produce(state, (draft) => {
        const { result, ...rest } = action.payload;
        draft.data = result;
        draft.meta = rest;
      });
    case types.CREATE:
      return produce(state, (draft) => {
        draft.create_loading = true;
      });
    case types.CREATE_SUCCESS:
      return produce(state, (draft) => {
        draft.create_loading = false;
        draft.create_success = true;
      });
    case types.CREATE_FAIL:
      return produce(state, (draft) => {
        draft.create_loading = false;
        draft.create_success = true;
      });
    case types.READ:
      return produce(state, (draft) => {
        draft.read_loading = true;
      });
    case types.READ_SUCCESS:
      return produce(state, (draft) => {
        draft.read_loading = false;
        draft.read_success = true;
      });
    case types.READ_FAIL:
      return produce(state, (draft) => {
        draft.read_loading = false;
        draft.read_success = false;
      });
    case types.UPDATE:
      return produce(state, (draft) => {
        draft.update_loading = true;
      });
    case types.UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.update_loading = false;
        draft.update_success = true;
      });
    case types.UPDATE_FAIL:
      return produce(state, (draft) => {
        draft.update_loading = false;
        draft.update_success = false;
      });
    case types.DELETE:
      return produce(state, (draft) => {
        draft.delete_loading = true;
      });
    case types.DELETE_SUCCESS:
      return produce(state, (draft) => {
        draft.delete_loading = false;
        draft.delete_success = true;
      });
    case types.DELETE_FAIL:
      return produce(state, (draft) => {
        draft.delete_loading = false;
        draft.delete_success = false;
      });
    case types.REQUEST_UPDATE_REMOTE_CONNECTIONS:
      return produce(state, (draft) => {
        draft.remoteConnections_update_loading = true;
      });

    case types.REQUEST_UPDATE_REMOTE_CONNECTIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.remoteConnections_update_loading = false;
      });
    default:
      return state;
  }
};

export default clientsReducer;
