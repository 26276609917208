import { EgretLoadable } from 'egret';
import { authRoles } from '../../../auth/authRoles';


const SubclientsList = EgretLoadable({
  loader: () => import('./index')
});

const SubclientsListRoute = [
  {
    path: '/subclients-list',
    exact: true,
    component: SubclientsList,
    auth: authRoles.client
  }
];

export default SubclientsListRoute;
