import produce from "immer";
import * as types from "./types";

const INITIAL_STATE = {
  create_devices_tasks_loading: false,
  create_devices_tasks_success: false,
  create_loading: false,
  create_success: false,
  read_loading: false,
  read_success: false,
  update_loading: false,
  update_success: false,
  delete_loading: false,
  delete_success: false,
  reading_favorite_devices: false,
  reading_devices_with_alerts: false,
  reading_devices_statistics: false,
  reading_devices_alerts: false,
  reading_devices_products: false,
  reading_device_tasks: false,
  reactivate_loading: false,
  reactivate_success: false,
  data: [],
  favorites: [],
  withAlerts: [],
  statistics: {
    page: 1,
    per_page: 10,
    pre_page: null,
    next_page: null,
    total: 0,
    total_pages: 0,
    result: [],
  },
  devicesAlerts: {
    meta: {
      page: 1,
      per_page: 10,
      pre_page: null,
      next_page: 2,
      total: 0,
      total_pages: 0,
    },
    data: [],
  },
  products: [],
  releases: [],
  tasks: [],
  meta: {
    page: 1,
    per_page: 10,
    pre_page: null,
    next_page: 2,
    total: 0,
    total_pages: 0,
  },
};

const devicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD:
      return produce(state, (draft) => {
        draft.data = [...state.data, { ...action.payload }];
        draft.formatted = {
          ...state.formatted,
          [action.payload.serial_number]: { ...action.payload },
        };
      });
    case types.SET_DATA:
      return produce(state, (draft) => {
        const { result, ...rest } = action.payload;
        draft.data = result;
        draft.meta = rest;
      });
    case types.SET_FORMATTED:
      return produce(state, (draft) => {
        draft.formatted = { ...action.payload };
      });
    case types.SET_FAVORITE_DEVICES: {
      return produce(state, (draft) => {
        draft.favorites = [...action.payload];
        draft.reading_favorite_devices = false;
      });
    }

    case types.CREATE:
      return produce(state, (draft) => {
        draft.create_loading = true;
      });
    case types.CREATE_SUCCESS:
      return produce(state, (draft) => {
        draft.create_loading = false;
        draft.create_success = true;
      });
    case types.CREATE_FAIL:
      return produce(state, (draft) => {
        draft.create_loading = false;
        draft.create_success = true;
      });
    case types.READ:
      return produce(state, (draft) => {
        draft.read_loading = true;
      });
    case types.READ_SUCCESS:
      return produce(state, (draft) => {
        draft.read_loading = false;
        draft.read_success = true;
      });
    case types.READ_FAIL:
      return produce(state, (draft) => {
        draft.read_loading = false;
        draft.read_success = false;
      });

    case types.READ_FAVORITE_DEVICES:
      return produce(state, (draft) => {
        draft.reading_favorite_devices = true;
      });
    case types.READ_FAVORITE_DEVICES_SUCCESS:
      return produce(state, (draft) => {
        draft.reading_favorite_devices = false;
      });
    case types.READ_FAVORITE_DEVICES_FAIL:
      return produce(state, (draft) => {
        draft.reading_favorite_devices = false;
      });

    case types.READ_DEVICES_WITH_ALERTS:
      return produce(state, (draft) => {
        draft.reading_devices_with_alerts = true;
      });
    case types.SET_DEVICES_WITH_ALERTS: {
      return produce(state, (draft) => {
        draft.withAlerts = [...action.payload];
        draft.reading_devices_with_alerts = false;
      });
    }
    case types.READ_DEVICES_WITH_ALERTS_SUCCESS:
      return produce(state, (draft) => {
        draft.reading_devices_with_alerts = false;
      });
    case types.READ_DEVICES_WITH_ALERTS_FAIL:
      return produce(state, (draft) => {
        draft.reading_devices_with_alerts = false;
      });

    case types.READ_DEVICES_STATISTICS:
      return produce(state, (draft) => {
        draft.reading_devices_statistics = true;
      });
    case types.SET_DEVICES_STATISTICS: {
      return produce(state, (draft) => {
        draft.statistics = { ...action.payload };
        draft.reading_devices_statistics = false;
      });
    }
    case types.READ_DEVICES_STATISTICS_FAIL:
    case types.READ_DEVICES_STATISTICS_SUCCESS:
      return produce(state, (draft) => {
        draft.reading_devices_statistics = false;
      });

    case types.READ_DEVICES_ALERTS:
      return produce(state, (draft) => {
        draft.reading_devices_alerts = true;
      });
    case types.SET_DEVICES_ALERTS: {
      return produce(state, (draft) => {
        const { result, ...rest } = action.payload;
        draft.devicesAlerts.data = result;
        draft.devicesAlerts.meta = rest;
        draft.reading_devices_alerts = false;
      });
    }
    case types.READ_DEVICES_ALERTS_FAIL:
    case types.READ_DEVICES_ALERTS_SUCCESS:
      return produce(state, (draft) => {
        draft.reading_devices_alerts = false;
      });
   
      case types.READ_DEVICES_PRODUCTS:
      return produce(state, (draft) => {
        draft.reading_devices_products = true;
      });
    case types.SET_DEVICES_PRODUCTS: {
      return produce(state, (draft) => {
        draft.products = [...action.payload.products];
        draft.releases = [...action.payload.releases];
        draft.reading_devices_products = false;
      });
    }
    case types.READ_DEVICES_PRODUCTS_FAIL:
      return produce(state, (draft) => {
        draft.reading_devices_products = false;
      });
    case types.READ_DEVICES_PRODUCTS_SUCCESS:
      return produce(state, (draft) => {
        draft.reading_devices_products = false;
      });
      case types.READ_DEVICE_TASKS:
      return produce(state, (draft) => {
        draft.reading_device_tasks = true;
      });
    case types.SET_DEVICE_TASKS: {
      return produce(state, (draft) => {
        draft.tasks = [...action.payload];
        draft.reading_device_tasks = false;
      });
    }
    case types.READ_DEVICE_TASKS_FAIL:
      return produce(state, (draft) => {
        draft.reading_device_tasks = false;
      });
    case types.READ_DEVICE_TASKS_SUCCESS:
      return produce(state, (draft) => {
        draft.reading_device_tasks = false;
      });

    case types.UPDATE:
      return produce(state, (draft) => {
        draft.update_loading = true;
      });
    case types.UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.update_loading = false;
        draft.update_success = true;
      });
    case types.UPDATE_FAIL:
      return produce(state, (draft) => {
        draft.update_loading = false;
        draft.update_success = false;
      });
    case types.DELETE:
      return produce(state, (draft) => {
        draft.delete_loading = true;
      });
    case types.DELETE_SUCCESS:
      return produce(state, (draft) => {
        draft.delete_loading = false;
        draft.delete_success = true;
      });
    case types.DELETE_FAIL:
      return produce(state, (draft) => {
        draft.delete_loading = false;
        draft.delete_success = false;
      });
    case types.REACTIVATE:
      return produce(state, (draft) => {
        draft.reactivate_loading = true;
      });
    case types.REACTIVATE_SUCCESS:
      return produce(state, (draft) => {
        draft.reactivate_loading = false;
        draft.reactivate_success = true;
      });
    case types.REACTIVATE_FAIL:
      return produce(state, (draft) => {
        draft.reactivate_loading = false;
        draft.reactivate_success = false;
      });
    default:
      return state;
  }
};

export default devicesReducer;
