import api from "./api";
import localStorageService from "./localStorageService";
import Cookies from "universal-cookie";

const cookies = new Cookies();
class JwtAuthService {
  logout = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("@integra/session_data");
    cookies.remove(`token`);
    cookies.remove(`refreshToken`);
    delete api.defaults.headers.common["Authorization"];
  };

  setSessionData = (data) => {
    if (!data) {
      this.logout();
      return;
    }
    const { token, refreshToken } = data;
    localStorage.setItem("jwt_token", token);
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    cookies.set(`token`, token, {
      path: "/",
      domain: ".dashboard.integrafacil.net.br",
      secure: true,
    });
    cookies.set(`refreshToken`, refreshToken, {
      path: "/",
      domain: ".dashboard.integrafacil.net.br",
      secure: true,
    });

    localStorageService.setItem("@integra/session_data", data);
  };
  getSessionData = () => {
    return localStorageService.getItem("@integra/session_data");
  };
  removeSessionData = () => {
    localStorage.removeItem("@integra/session_data");
  };

  getLocalRefreshToken = () => {
    return this.getSessionData()?.refreshToken;
  };
}

export default new JwtAuthService();
