import * as types from './types';

export const setUsersData = (devices) => ({
  type: types.SET_DATA,
  payload: [...devices]
});

export const createUsers = (formData, callback = () => {}) => ({
  type: types.REQUEST_CREATE,
  payload: { formData, callback }
});

export const getUsers = () => ({
  type: types.REQUEST_READ
});

export const updateUsers = ({ id, ...formData }, callback = () => {}) => ({
  type: types.REQUEST_UPDATE,
  payload: { id, formData, callback }
});

export const inactivateUsers = (id, callback = () => {}) => ({
  type: types.REQUEST_DELETE,
  payload: { id, callback }
});
