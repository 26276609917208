import {
  takeLatest,
  put,
  all,
  //  call 
} from 'redux-saga/effects';
import * as types from './types';
// import api from 'app/services/api';

export function* get() {
  yield put({
    type: types.GET,
    payload: []
  })
}

export function* create({ payload }) {

}

export function* deleteOne({ payload }) {

}

export function* deleteAll() {

}

export default all([
  takeLatest(types.REQUEST_GET, get),
  takeLatest(types.REQUEST_CREATE, create),
  takeLatest(types.REQUEST_DELETE, deleteOne),
  takeLatest(types.REQUEST_DELETE_ALL, deleteAll),
]);